<!--
 * @Description: 报价列表（采购商）
 * @Author: zhang zhen
 * @Date: 2023-07-28 16:52:51
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 15:54:06
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePricePurchaserList.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.title"
          placeholder="请输入需求单号、需求名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="reaLoad" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="报价状态">
          <checkboxSearch v-model="queryParams.status" :options="mainOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="合作状态">
          <checkboxSearch v-model="queryParams.quoteSource" :options="quoteOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="报价时间">
          <radioGroup
            v-model="validityDateScoped"
            :options="otherOptions"
            :needLoad="false"
            @change="handleChangeRadio($event, 'validityDate')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="validityDateRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'validityDateScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="validityDateRange.length">{{ validityDateRange[0] }} ~ {{ validityDateRange[1] }}</span>
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
        <a-form-model-item label="处理时间">
          <radioGroup
            v-model="dealWidthScoped"
            :options="otherOptions"
            :needLoad="false"
            @change="handleChangeRadio($event, 'dealWidth')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="dealWidthRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'dealWidthScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="dealWidthRange.length">{{ dealWidthRange[0] }} ~ {{ dealWidthRange[1] }}</span>
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="index"
      >
        <template slot="quoteSource" slot-scope="text, record">
          {{ text == 1 ? '主动报价' : '邀请报价' }}
        </template>
        <template slot="quoteDeadline" slot-scope="text, record">
          <div>
            {{ (text && text.substring(0, 10)) || '' }}
          </div>
        </template>
        <template slot="statusLabel" slot-scope="text, record">
          <span class="table-status-btn" :class="quoteStyle[record.status + '']">
            {{ quoteDictList[record.status] || '' }}
          </span>
        </template>
        <template slot="bizStatus" slot-scope="text, record">
          <span class="table-status-btn" :class="statusDictStyle[record.bizStatus]">
            {{ statusDictList[text] || '' }}
          </span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="handleSwitchTab(record)" v-if="record.status != '3'">查看报价</a>
          <template v-if="record.status == '0'">
            <a-divider type="vertical" v-if="record.status != '3'" />
            <a @click="handleEmitIgnore(record)">忽略</a>
            <a-divider type="vertical" />
            <a @click="handleEmitChoose(record)">确定合作</a>
          </template>
          <template v-if="record.orderNo">
            <a-divider type="vertical" />
            <a @click="handleViewOrder(record)">查看订单</a>
          </template>
          <template v-if="record.bizStatus == '0' && record.status == '1'">
            <a-divider type="vertical" />
            <a @click="handleCheckSign(record)">发起订单</a>
          </template>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <input ref="fileUploaderRef" type="file" class="fileUploader" @change="handleFileUpload" />
    <SplitOrderInfo ref="splitOrderInfoRef" @ok="reaLoad" />
    <!-- 发起签单 -->
    <StartSign ref="StartSignRef" @ok="handleLoadData" />

    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showA"
      okText="确认"
      centered
      @ok="handleIgnore"
      @cancel="showA = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span
          >是否确定忽略当前报价信息？<br />
          确定忽略后，当前报价信息将不能建立合作。</span
        >
      </div>
    </a-modal>

    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showB"
      okText="确认"
      centered
      @ok="chooseHim"
      @cancel="showB = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>是否确定合作当前报价企业？</span>
      </div>
    </a-modal>
    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showC"
      okText="去签署"
      centered
      @ok="handleActionC"
      @cancel="showC = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您与对方企业是第一次合作，订单合作前双方请签署合作合同!</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import SplitOrderInfo from './modules/splitOrderInfo.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import StartSign from './modules/startSign.vue'
import { getDateQuoteRange } from '@/util/utils'

export default {
  name: 'quotePriceList',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    SplitOrderInfo,
    radioGroup,
    StartSign,
  },

  data() {
    return {
      chooseItem: {},
      showA: false,
      showB: false,
      showC: false,
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined,
      },
      dealWidthScoped: undefined,
      validityDateScoped: undefined,
      rangeDate: [],
      columns: [
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo',
          width: 218,
        },
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle',
          ellipsis: true,
          width: 200,
        },
        {
          title: '报价方',
          dataIndex: 'quoteSource',
          key: 'quoteSource',
          width: 100,
          scopedSlots: { customRender: 'quoteSource' },
        },
        {
          title: '报价时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180,
        },
        {
          title: '报价状态',
          dataIndex: 'statusLabel',
          key: 'statusLabel',
          width: 110,
          scopedSlots: { customRender: 'statusLabel' },
        },
        {
          title: '合作状态',
          dataIndex: 'bizStatus',
          key: 'bizStatus',
          width: 110,
          scopedSlots: { customRender: 'bizStatus' },
        },
        {
          title: '处理时间',
          dataIndex: 'quoteDeadline',
          key: 'quoteDeadline',
          scopedSlots: { customRender: 'quoteDeadline' },
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 210,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      quoteOptions: [
        {
          dictKey: '0',
          dictValue: '待开始',
        },
        {
          dictKey: '1',
          dictValue: '进行中',
        },
        {
          dictKey: '3',
          dictValue: '已完成',
        },
      ],
      otherOptions: [
        {
          dictKey: '1',
          dictValue: '本月',
        },
        {
          dictKey: '2',
          dictValue: '下月',
        },
        {
          dictKey: '3',
          dictValue: '两月后',
        },
      ],
      mainOptions: [
        {
          dictKey: '0',
          dictValue: '待处理',
        },
        {
          dictKey: '1',
          dictValue: '已合作',
        },
        {
          dictKey: '2',
          dictValue: '已拒绝',
        },
      ],
      activeInfo: {},
      requestType: 'post',
      statusDictList: {
        0: '待开始',
        1: '进行中',
        3: '已完成',
      },
      statusDictStyle: {
        0: 'status_8',
        1: 'status_4',
        3: 'status_10',
      },
      quoteStyle: {
        0: 'status_4',
        1: 'status_6',
        2: 'status_2',
        3: 'status_8',
        '-1': 'status_10',
      },
      quoteDictList: {
        0: '待处理',
        1: '已合作',
        2: '已拒绝',
      },
      url: {
        list: '/quote/list', //0827
      },
      validityDateRange: [],
      dealWidthRange: [],
      disableMixinCreated: true,
    }
  },
  created() {
    if (this.$route.query && this.$route.query.purchaseNo) {
      this.queryParams.title = this.$route.query.purchaseNo
      this.handleLoadData()
    } else {
      this.handleLoadData(1);
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleActionC() {
      this.$router.push({
        path: '/ContractManagement',
        query: {
          contractNo: this.activeInfo.contractNo
        }
      })
    },
    handleStartSign(record) {
      this.$refs.StartSignRef.handleSign(record)
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    /* 忽略报价 */
    handleEmitIgnore(item) {
      this.chooseItem = item
      this.showA = true
    },
    handleIgnore() {
      const { quoteId } = this.chooseItem
      getAction('/quote/ignore', { quoteId: quoteId })
        .then((res) => {
          const { success, message } = res
          this.showA = false
          if (success) {
            this.$message.success('操作成功')
            this.reaLoad()
          } else {
            this.$message.error(message)
          }
        })
        .catch((e) => {
          this.showA = false
        })
    },

    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0]
      let formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('dir', 'images')
      uploadAction('/file/images/upload', formData).then((res) => {
        const { success, message, data } = res
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || '',
          }).then((res) => {
            const { success, message } = res
            if (success) {
              this.reaLoad()
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    handleUploadContract(item) {
      this.activeInfo = {
        purchaseId: item.purchaseId || '',
        contractNo: item.contractNo || '',
      }
      const uploader = this.$refs.fileUploaderRef
      uploader.value = ''
      uploader.click()
    },
    handleSearch() {
      this.handleLoadData(1)
    },
    handleConfirmSplit(item) {
      this.$refs.splitOrderInfoRef.handleShowOrder({ ...item })
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        }
      }
      const { quoteSource, status, title, bizStatus } = this.queryParams
      let quoteSourceList = [],
        statusList = [],
        params = {}
      if (quoteSource) {
        quoteSourceList = quoteSource.split(',')
      }
      if (status) {
        statusList = status.split(',')
      } else {
        statusList = ['0', '1', '2']
      }
      if (bizStatus) {
        params['bizStatus'] = bizStatus.split(',')
      }
      // 报价时间处理
      if (!!this.validityDateScoped && this.validityDateScoped != -1) {
        const [quoteTimeStart, quoteTimeEnd] = getDateQuoteRange(this.validityDateScoped)
        params = { ...params, quoteTimeStart, quoteTimeEnd }
      } else if (this.validityDateScoped != -1 && this.validityDateRange.length) {
        const [quoteTimeStart, quoteTimeEnd] = this.validityDateRange
        params = { ...params, quoteTimeStart, quoteTimeEnd }
      }
      // 处理时间
      if (!!this.dealWidthScoped && this.dealWidthScoped != -1) {
        const [dealTimeStart, dealTimeEnd] = getDateQuoteRange(this.dealWidthScoped)
        params = { ...params, dealTimeStart, dealTimeEnd }
      } else if (this.dealWidthScoped != -1 && this.dealWidthRange.length) {
        const [dealTimeStart, dealTimeEnd] = this.dealWidthRange
        params = { ...params, dealTimeStart, dealTimeEnd }
      }

      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        title: title || '',
        status: statusList,
        quoteSource: quoteSourceList,
        pagesSize: pageSize,
        pageNum,
        ...params,
        ...this.sorter,
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    /* 参与报价 */
    handleQuote(item) {
      this.$router.push(
        `/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&quoteSource=${item.quoteSource}&quoteId=${item.quoteId}`
      )
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, businessId, status } = item
      this.$router.push(
        `/quotePriceManagement/quotePriceInfoView?purchaseId=${purchaseId}&quoteId=${quoteId}&isDetail=${
          status == 0 ? '2' : '1'
        }&businessId=${businessId}`
      )
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.queryParams = {}
      this.rangeDate = []
      this.dealWidthRange = []
      this.dataSource = []
      this.validityDateRange = []
      this.dealWidthScoped = undefined
      this.validityDateScoped = undefined
      this.handleLoadData(1)
    },
    /* 查看订单 */
    handleViewOrder(e) {
      this.$router.push({
        path: '/orderManagement',
        query: {
          orderNo: e.orderNo,
        },
      })
    },
    /* 确定合作 */
    handleEmitChoose(item) {
      this.showB = true
      this.chooseItem = item
    },
    chooseHim() {
      const { businessId, purchaseId, quoteId } = this.chooseItem
      postAction('/quote/confirm', {
        businessId,
        purchaseId,
        quoteId,
      })
        .then((res) => {
          const { success, message } = res
          this.showB = false
          if (success) {
            this.$message.success(message)
            this.reaLoad()
          } else {
            this.$message.warning(message)
          }
        })
        .catch((e) => {
          this.showB = false
        })
    },
    /* 发起订单 */
    handleCheckSign(item) {
      this.activeInfo = item
      if (item.contractStatus == 2) {
        // 已经签署完合同了
        this.handleStartSign(item)
      } else {
        // 弹框提示签合同
        this.showC = true
      }
    },
    // contractStatus == 2
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;
    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.info-card {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  display: flex;
  align-items: flex-start;

  img {
    width: 21px;
    height: 21px;
    margin-top: 2px;
  }

  span {
    margin-left: 10px;
  }
}
</style>
